import { Link } from 'react-router-dom';
import './DesignCard.scss';

function DesignCard({ Thumb, projectId, shortDesc }) {
    return (
        <>
            <section>
                <Link to={`/project/${projectId}`}>
                    <div className='designcard'>
                        <img className='designcard_img' src={Thumb} alt={`${projectId} Project Thumbnail`} />
                        <p className='designcard_desc'>
                            {shortDesc}
                        </p>
                    </div>
                </Link>
            </section>

        </>
    );
}

export default DesignCard;


