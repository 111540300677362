import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.scss';
import Logo from '../../assets/icons/Portfolio_Logo.svg';

function Header() {
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY && currentScrollY > 50) {
            // User is scrolling down and past 50px
            setShowHeader(false);
        } else if (currentScrollY < lastScrollY || currentScrollY <= 50) {
            // User is scrolling up or near the top of the page
            setShowHeader(true);
        }

        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const scrollToTop = () => {
        if (location.pathname === '/') {
            scroll.scrollToTop({ smooth: true });
        } else {
            window.location.href = '/';
        }
    };

    const scrollToAbout = () => {
        if (location.pathname === '/') {
            scroll.scrollTo(700, { smooth: true });
        } else {
            navigate('/#aboutme');
        }
    };

    const scrollToProjects = () => {
        if (location.pathname === '/') {
            scroll.scrollTo(2000, { smooth: true });
        } else {
            navigate('/#projects');
        }
    };

    const scrollToContact = () => {
        if (location.pathname === '/') {
            scroll.scrollTo(3400, { smooth: true });
        } else {
            navigate('/#contact');
        }
    };

    return (
        <header className={`navbar ${showHeader ? 'navbar--visible' : 'navbar--hidden'}`}>
            <nav className="navbar__cont">
                <div className="navbar__sec">
                    <div className="navbar__links--mobile" onClick={scrollToTop}>Home</div>
                    <div className="navbar__logo" onClick={scrollToTop}>
                        <img src={Logo} alt="Bren.Dev Logo" className="navbar__logo--desktop" />
                    </div>
                    <div onClick={scrollToContact} className="navbar__links--mobile">Contact</div>
                </div>
                <div className="navbar__sec">
                    <ul className="navbar__opts">
                        <li>
                            <div onClick={scrollToAbout} className="navbar__links">Who</div>
                        </li>
                        <li>
                            <div onClick={scrollToProjects} className="navbar__links">What</div>
                        </li>
                        <li>
                            <div onClick={scrollToContact} className="navbar__links">Where</div>
                        </li>
                    </ul>
                    <div className="navbar__opts">
                        <input id="theme-switch" className="navbar--theme" type="checkbox" />
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
