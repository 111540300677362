import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectInfo from '../../components/ProjectInfo/ProjectInfo';
import designProjectsData from '../../data/designProjectsData.json';
import codeProjectsData from '../../data/codeProjectsData.json';

// Preload all images using require.context()
const designImages = require.context('../../assets/images/projects/design', true, /\.(webp|jpg)$/);
const codeImages = require.context('../../assets/images/projects/code', true, /\.(webp|jpg)$/);

function ProjectPage() {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [projImages, setProjImages] = useState([]);
    const [heroImage, setHeroImage] = useState('');

    // Get the image path from the preloaded images
    const getImagePath = (folderName, fileName, isCodeProject) => {
        const context = isCodeProject ? codeImages : designImages;
        try {
            return context(`./${folderName}/${fileName}`);
        } catch (error) {
            console.error(`Failed to load image: ${folderName}/${fileName}`);
            return null; // Return null if the image is not found
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        // Determine which dataset to use based on the project ID
        let currentProject = designProjectsData.find(proj => proj.id === projectId);

        // If not found in design projects, check code projects
        if (!currentProject) {
            currentProject = codeProjectsData.find(proj => proj.id === projectId);
        }

        if (currentProject) {
            setProject(currentProject);

            // Determine if the project is from the "code" or "design" category
            const isCodeProject = codeProjectsData.includes(currentProject); // Check if it's in the code dataset

            // Load the hero image
            const heroPath = getImagePath(currentProject.imageFolder, 'image-0.webp', isCodeProject);
            setHeroImage(heroPath);

            // Load the project images
            const images = Array.from({ length: currentProject.imageCount }, (_, index) => {
                return getImagePath(currentProject.imageFolder, `image-${index + 1}.jpg`, isCodeProject);
            });
            setProjImages(images);
        }
    }, [projectId]);

    if (!project) {
        return <p>Project not found</p>;
    }

    return (
        <ProjectInfo
            projTitle={project.title}
            projHero={heroImage}
            projType={project.type}
            projDesc={project.description}
            projKeyword={project.keyword}
            projectImages={projImages}
            stackTags={project.stackTags}
        />
    );
}

export default ProjectPage;
