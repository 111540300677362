import './NotFound.scss';
// import notFoundImg from '../../assets/images/404-page--img.png';
import frame1 from '../../assets/images/frame1.png';
import frame2 from '../../assets/images/frame2.png';

function NotFound() {
    return (
        <>
            <section className='notfound'>
                <div className='notfound__cont'>
                    {/* <img className='notfound__img' src={notFoundImg} alt="Ra-Kun apologizes!" /> */}
                    <div className='notfound__group'>
                        <p className='notfound__group--text'>*Gomen Nasai*</p>
                        <img className='notfound__group--img' src={frame1} alt="Ra-Kun apologizes! Frame 1" />
                        <img className='notfound__group--img' src={frame2} alt="Ra-Kun apologizes! Frame 2" />
                    </div>
                    <div className='notfound__desc'>
                        <h1 className='notfound__desc--404'>404</h1>
                        <h2 className='notfound__desc--text'>Page Not Found <br /> Or <br /> Page Does Not Exist</h2>
                        <a className='notfound__desc--link' href='/'>Return to Home Page</a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NotFound;