import { animateScroll as scroll } from 'react-scroll'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import './Footer.scss';

import GitHubIconAnim from '../../assets/animated icons/github-animated-icon.gif';
import GitHubIconStatic from '../../assets/animated icons/github-icon.svg';
import LinkedInIconAnim from '../../assets/animated icons/linkedin-animated-icon.gif';
import LinkedInIconStatic from '../../assets/animated icons/linkedin-icon.svg';

function Footer() {
    const location = useLocation();

    const greetings = [
        "🦝",            // Ra-Kun *
        "Hello",            // English
        "Hola",             // Spanish
        "Bonjour",          // French
        "Hallo",            // German
        "Ciao",             // Italian
        "Olá",              // Portuguese
        "Привет",           // Russian
        "你好",             // Chinese
        "こんにちは",        // Japanese
        "안녕하세요",        // Korean
        "مرحبا",           // Arabic
        "हैलो",              // Hindi
        "Sawubona",         // Zulu
        "שלום",             // Hebrew
        "Hej",              // Swedish
        "Chirp!",           // Bird *
        "Sawasdee",         // Thai
        "Saluton",          // Esperanto
        "Xin chào",         // Vietnamese
        "Kamusta",          // Filipino
        "Helo",             // Welsh
        "Aloha",            // Hawaiian
        "Merhaba",          // Turkish
        "Meow!",            // Cat *
        "Namaste",          // Nepali
        "Selamat pagi",     // Malay/Indonesian
        "Kamusta",          // Cebuano
        "Konnichiwa",       // Japanese (formal)
        "Jambo",            // Swahili
        "Tere",             // Estonian
        "Manao ahoana",     // Malagasy
        "Halo",             // Indonesian
        "Hei",              // Finnish
        "Wuff!",            // Dog *
        "Halo",             // Malay
        "Yassou",           // Greek
        "Zdravo",           // Serbian
        "Buna",             // Romanian
        "Salut",            // French (informal)
        "Witam",            // Polish
        "The One Piece is Real!",      // Funny *
        "Hoi",              // Dutch
        "Kumusta",          // Tagalog
        "Dzien dobry",      // Polish (formal)
        "Alô",              // Portuguese (informal)
        "Hei",              // Norwegian
        "Moo!",            // Cow *
        "Ahoj",             // Czech
    ];

    useEffect(() => {
        const svgElement = document.querySelector('.footer__svg--rakun');

        const updateGreeting = () => {
            // Picks a random greeting .<.
            const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];
            // Set the CSS variable for the content :P
            svgElement.style.setProperty('--greeting-content', `"${randomGreeting}"`);
        };

        svgElement.addEventListener('mouseenter', updateGreeting);

        return () => {
            svgElement.removeEventListener('mouseenter', updateGreeting);
        };
    }, []);

    const handleReplaceSrc = () => {
        if (window.matchMedia && window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            const iconElement1 = document.querySelector('.icon-linkedin');
            iconElement1.setAttribute('src', LinkedInIconStatic);

            const iconElement2 = document.querySelector('.icon-github');
            iconElement2.setAttribute('src', GitHubIconStatic);
        }
    };

    useEffect(() => {
        handleReplaceSrc();
    }, []);

    const scrollToTop = () => {
        if (location.pathname === '/') {
            // If on the home page, scroll to the top
            scroll.scrollToTop({ smooth: true, });
        } else {
            // If on another page, navigate to the home page
            window.location.href = '/';
        }
    };

    const scrollToAbout = () => {
        if (location.pathname === '/') {
            // If on the home page, scroll to the top
            scroll.scrollTo(700, { smooth: true, });
        } else {
            // If on another page, navigate to the home page
            window.location.href = '/';
        }
    };

    const scrollToProjects = () => {
        if (location.pathname === '/') {
            // If on the home page, scroll to the top
            scroll.scrollTo(2000, { smooth: true, });
        } else {
            // If on another page, navigate to the home page
            window.location.href = '/';
        }
    };

    const scrollToContact = () => {
        if (location.pathname === '/') {
            // If on the home page, scroll to the top
            scroll.scrollTo(2700, { smooth: true, });
        } else {
            // If on another page, navigate to the home page
            window.location.href = '/';
        }
    };

    return (
        <>
            <footer className='footer'>
                <div className='footer__cont'>
                    <div className='footer__sec'>
                        <div className='footer__sec--socials'>
                            <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/bren-gonzalez/'>
                                <img className='footer__sec--icon icon-linkedin' src={LinkedInIconAnim} alt="LinkedIn Profile Link" />
                            </a>
                            <a target='_blank' rel="noreferrer" href='https://github.com/BreadwWater'>
                                <img className='footer__sec--icon icon-github' src={GitHubIconAnim} alt="GitHub Profile Link" />
                            </a>
                        </div>

                        <div className='footer__sec--group'>
                            <h3 className='footer__sec--name'>Brenda Gonzalez</h3>
                            <p className='footer__sec--text'>Software Engineer • Graphic Designer • 3D Modeler</p>
                            <div className='footer__sec--socials'>
                                <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/bren-gonzalez/'>
                                    <img className='footer__sec--icon-desktop icon-linkedin' src={LinkedInIconAnim} alt="LinkedIn Profile Link" />
                                </a>
                                <a target='_blank' rel="noreferrer" href='https://github.com/BreadwWater'>
                                    <img className='footer__sec--icon-desktop icon-github' src={GitHubIconAnim} alt="GitHub Profile Link" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='footer__sec'>
                        <div className='footer__svg--rakun'>
                            <svg viewBox="0 0 719.06 595"><g><g data-name="Layer 6 copy"><g id="Layer_3_copy_4" data-name="Layer 3 copy 4"><path d="M684.82,37.18,555.84,0,568.5,92.78l-22.31-5L503.32,9.64l-115.78,4.2,26.76,44.5L345.47,43,102.23,204.81l-.35,172.9L0,433.39,135.92,573.16,421.47,595,719.06,433.6ZM146.07,551.47,36.62,438.91,124.25,391h0l127-38.26L367.19,430.5l140.14-71.83L460.89,199.14l-119.6-17.63-83.08,66.42-94.68-57,186.39-124L368.29,71,480.2,139.4l45.41,105.43-11.76,9.85,8.54,24.43,39.86,9,10.91-8.25-6.94,20.8,13.54,18.86L574.25,393l14.9,1.12,4.37-58.35,21.42-1,3.11-10.24,22,44,13.36-6.66-29-58.11,3-9.83L616.05,271.8l11.07,4.2,36.11-16.74-2.33-25.7-39.81-14.93-11.47,8.44,5-25.74L598.2,183.76,592,130l-19-13.24,98.18,21.93,24.3,282.21L416.59,572.15Zm-1.42-238.79,45.69-22.62L241,308.54l-8.61,23.61-40.56-14.79-36,17.84Zm173.44-3.57,57.46-24.39,64.09,19.22L432.42,328,377,311.4l-49.11,20.85ZM663.82,54.44l5.25,60.8L591.82,98l-9.13-66.94Zm-40.4,181,23.46,8.8.53,5.88-20.86,9.67-21.71-8.28.54-2.78Zm-19.7,84.89-4.79.23L582.8,298.13l11.41-34.22.16-.12H595l.22.09,16.12,31.52Zm-70-54-2.39-6.86L551,243.11l29.46,10.27.28,2-22,16.62Zm57.84-24.9-1.87-.66-22.37-29,7-25.1,5.28-.93L598.54,206ZM562.5,173.61,551,215l13,16.83-16.07-5.6-10.29,8.61L492,129.13l-86.76-53,121,26.37,51.62,35.89,3.73,31.87Z" fill="" /><polygon points="169.26 490.94 212.21 435.96 103.6 438.39 169.26 490.94" fill="" /></g></g></g></svg>
                        </div>

                        <ul className='footer__sec--sitenav'>
                            <li><div onClick={scrollToTop} className='footer__sec--link'>Home</div></li>
                            <li><div onClick={scrollToAbout} className='footer__sec--link'>Who</div></li>
                            <li><div onClick={scrollToProjects} className='footer__sec--link'>What</div></li>
                            <li><div onClick={scrollToContact} className='footer__sec--link'>Where</div></li>
                        </ul>
                        <p className='footer__text'>
                            © 2024 BrendaG.Dev | All rights reserved.
                        </p>
                        <p className='footer__text'>
                            Credit to <a className='footer__text--highlight' rel="noreferrer" target='_blank' href="https://unsplash.com/">Unsplash</a> for all project hero images!
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;