import './DesignProjPage.scss';
import DesignCard from '../../components/DesignCard/DesignCard.js';

function DesignProjPage(projLink) {
    window.scrollTo(0, 0)

    return (

        <>
            <section className='design-page'>
                <div className='design-page__hero'>
                    <h1 className='design-page__hero--text'>Design Projects</h1>
                    <h3 className='design-page__hero--text'>A Collection of My Latest Works</h3>
                </div>
                <div className='design-page__cont'>
                    <div className='design-page__cont--cards'>

                        <DesignCard
                            projectId={`albumui`}
                            shortDesc={`UI | Album UI`}
                            Thumb={require(`../../assets/images/projects/design/project-15/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`mothersdayad`}
                            shortDesc={`Ad | Mother's Day`}
                            Thumb={require(`../../assets/images/projects/design/project-14/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`spoonfed`}
                            shortDesc={`Logo | Spoon Fed`}
                            Thumb={require(`../../assets/images/projects/design/project-13/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`sweetmapleco`}
                            shortDesc={`Packaging | Sweet Maple Co`}
                            Thumb={require(`../../assets/images/projects/design/project-12/image-1.jpg`)}
                        />



                        <DesignCard
                            projectId={`dogfoodpackage`}
                            shortDesc={`Product | Dog Food Packaging`}
                            Thumb={require(`../../assets/images/projects/design/project-10/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`randomicons`}
                            shortDesc={`Iconography | Random Icons`}
                            Thumb={require(`../../assets/images/projects/design/project-11/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`avatarminimalist`}
                            shortDesc={`Poster | Minimalist Elementals`}
                            Thumb={require(`../../assets/images/projects/design/project-9/image-4.jpg`)}
                        />

                        <DesignCard
                            projectId={`birdIcons`}
                            shortDesc={`Iconography | Birds`}
                            Thumb={require(`../../assets/images/projects/design/project-7/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`thePenguinPoster`}
                            shortDesc={`Poster | The Penguin`}
                            Thumb={require(`../../assets/images/projects/design/project-8/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`odysseycruiseline`}
                            shortDesc={`Website | Odyssey Cruiseline`}
                            Thumb={require(`../../assets/images/projects/design/project-5/image-2.jpg`)}
                        />

                        <DesignCard
                            projectId={`myportfolio`}
                            shortDesc={`Website | Personal Portfolio Website`}
                            Thumb={require(`../../assets/images/projects/design/project-4/image-4.jpg`)}
                        />

                        <DesignCard
                            projectId={`letschill`}
                            shortDesc={`Branding | Let's Chill`}
                            Thumb={require(`../../assets/images/projects/design/project-2/image-7.jpg`)}
                        />

                        <DesignCard
                            projectId={`eternalframes`}
                            shortDesc={`Logo Update | Eternal Frames`}
                            Thumb={require(`../../assets/images/projects/design/project-3/image-1.jpg`)}
                        />

                        <DesignCard
                            projectId={`stairbox`}
                            shortDesc={`Branding | Stair Box`}
                            Thumb={require(`../../assets/images/projects/design/project-1/image-5.jpg`)}
                        />

                        <DesignCard
                            projectId={`raccoon3d`}
                            shortDesc={`3D Model | Bread Raccoon`}
                            Thumb={require(`../../assets/images/projects/design/project-6/image-1.jpg`)}
                        />
                    </div>
                    <div className='design-page__cont--group'>
                        <p className='design-page__cont--text'>With More Coming Soon!</p>
                        <div className='design-page__cont--img' >
                            <svg className='design-page__cont--svg' viewBox="0 0 719.06 594.99"><path className="cls-1" d="M684.82,37.18,555.84,0,568.5,92.78l-22.31-5L503.32,9.64l-115.78,4.2,26.76,44.5L345.47,43,102.23,204.81l-.35,172.9L0,433.39,135.92,573.16,421.47,595,719.06,433.6ZM146.07,551.47,36.62,438.91,124.25,391h0l127-38.26L367.19,430.5l140.14-71.83L460.89,199.14l-119.6-17.63-83.08,66.42-94.68-57,186.39-124L368.29,71,480.2,139.4l45.41,105.43-11.76,9.85,8.54,24.43,39.86,9,10.91-8.25-6.94,20.8,13.54,18.86L574.25,393l14.9,1.12,4.37-58.35,21.42-1,3.11-10.24,22,44,13.36-6.66-29-58.11,3-9.83L616.05,271.8,627.12,276l36.11-16.74-2.33-25.7-39.81-14.93-11.47,8.44,5-25.74-16.42-17.57L592,130l-19-13.24,98.18,21.93L695.48,420.9,416.59,572.15Zm-1.42-238.79,45.69-22.62L241,308.54l-8.61,23.61-40.56-14.79-36,17.84Zm173.44-3.57,57.46-24.39,64.09,19.22L432.42,328,377,311.4l-49.11,20.85ZM663.82,54.44l5.25,60.8L591.82,98l-9.13-66.94Zm-40.4,181,23.46,8.8.53,5.88-20.86,9.67-21.71-8.28.54-2.78Zm-19.7,84.89-4.79.23L582.8,298.13l11.41-34.22.16-.12.62,0,.22.09,16.12,31.52Zm-70-54-2.39-6.86L551,243.11l29.46,10.27.28,2L558.77,272Zm57.84-24.9-1.87-.66-22.37-29,7-25.1,5.28-.93L598.54,206Zm-29.06-67.82L551,215l13,16.83-16.07-5.6-10.29,8.61L492,129.13l-86.76-53,121,26.37,51.62,35.89,3.73,31.87Z" /><polygon className="cls-1" points="169.26 490.94 212.21 435.96 103.6 438.39 169.26 490.94" /></svg>
                            <svg className='design-page__cont--svg' viewBox="0 0 1025.22 633.03"><rect className="cls-1" x="66.6" y="58.57" width="23.59" height="164.99" transform="matrix(0.52, -0.85, 0.85, 0.52, -82.87, 134.85)" /><rect className="cls-1" x="0.28" y="272.11" width="122.44" height="23.59" transform="translate(-7.23 1.66) rotate(-1.46)" /><rect className="cls-1" x="185.55" y="-1.03" width="23.59" height="122.44" transform="translate(-6.42 92.61) rotate(-26)" /><rect className="cls-1" x="902.44" y="532.06" width="23.59" height="115.03" transform="translate(-101.14 980) rotate(-53.85)" /><rect className="cls-1" x="951.61" y="402.2" width="23.59" height="122.44" transform="translate(443.35 1397.48) rotate(-86.58)" /></svg>
                        </div >
                    </div >
                </div >
            </section >
        </>
    );
}

export default DesignProjPage;

